import React,{useEffect, useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {ToastContainer, toast } from 'react-toastify';
import "../Css/logincss.css";
import profile from "../Images/Profile.png";
import email from "../Images/Email.png";
import password from "../Images/Password.png";
import {login} from "../apis/api";
import CCLogo from '../Images/CCLogo.png'

const Login=()=>{

    useEffect(()=>{
        document.title="Econet||Login";
    },[]);

    const[data, addData]=useState({});

    const navigate=useNavigate();

    const onSubmit=(e)=>{
        // console.log(data);
        hitOnApi(data);
        e.preventDefault();
    };

    const hitOnApi=(data)=>{
        axios.post(`${login}`, data)
        .then(
            (response)=>{
                // console.log(response);
                if(response.data.response==='')
                {
                    toast.error("Wrong Credentials");
                }
                else
                {
                    toast.success("Login Success");
                    sessionStorage.setItem("username","admin");
                    navigate("/dashboard");
                }
            },
            (error)=>{
                console.log(error);
                toast.error("Wrong Credentials");
            }
        )
    }

    return(
        <div>
            <ToastContainer/>
            <div className="main">
                <div className="sub-main">
                    <div>
                        <div className="imgs">
                            <div className="container-image">
                                <img src={CCLogo} alt="Profile" className="profile"/>
                            </div>
                        </div>
                        <div>
                            <h2>Yo Games Pro</h2>
                            <form onSubmit={onSubmit}>
                                <div>
                                    <img src={email} alt="Email" className="email"/>
                                    <input type="text" name="username" id="username"
                                    placeholder="Username" autoComplete="off" required
                                    className="name" onChange={(e)=>{
                                        addData({...data, username:e.target.value})
                                    }}/>
                                </div>
                                <div className="second-div">
                                    <img src={password} alt="Password" className="email"/>
                                    <input type="password" name="password" id="password"
                                    placeholder="Password" autoComplete="off" required
                                    className="name" onChange={(e)=>{
                                        addData({...data,password:e.target.value})
                                    }}/>
                                </div>
                                <div className="bttn-div">
                                    <button className="bttn">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;