import React,{useEffect, useState} from "react";
import axios from "axios";
import { Audio, Circles , Rings, TailSpin} from  'react-loader-spinner'
import {todaySub} from "../apis/api";
import "../Css/table.css";

const Body=()=>{

    const[data,addData]=useState([]);

    useEffect(()=>{
        axios.get(`${todaySub}`)
        .then(
            (response)=>{
                // console.log(response);
                addData(response.data);
                document.getElementById("spinnerDiv").style.display="none";
            },
            (error)=>{
                console.log(error);
            }
        )
    },[]);

    return(
        <div className="container mt-3 ">

            <div className="spinnerr" id="spinnerDiv">
            <TailSpin
                height="100"
                width="100"
                color='grey'
                ariaLabel='loading'
                className="spinnerrr"
            />
            </div>

            <div className="head">
                <button className="btn btn-success">Today's Subscribers</button>
            </div>

            <table className="table table-dark table-bordered table-div">
                <thead>
                    <tr>
                        {/* <th>Sr. No</th> */}
                        <th>MSISDN</th>
                        <th>Pack</th>
                        <th>Amount</th>
                        <th>SubDate</th>
                    </tr>
                </thead>
                     {data.map((tbl,index)=>{
                    return (
                        <tbody>
                        <tr>
                            {/* <td>{index+1}</td> */}
                            <td>{tbl.ani}</td>
                            <td>{tbl.pack}</td>
                            <td>{tbl.default_amount}</td>
                            <td>{tbl.sub_date_time}</td>
                        </tr>
                    </tbody>
                     );
                 })}

        
            </table>
        </div>
    );
}
export default Body;

        // <div>
        //         {data.map((tbl,index)=>{
        //             return (
        //                 <SubTable ani={tbl.ani} pack={tbl.pack} default_amount={tbl.default_amount}
        //                 sub_date_time={tbl.sub_date_time} index={index}/>                        
        //             );
        //         })}
        // </div>