import React from "react";
import { AppBar, Toolbar, IconButton, Typography, Stack , Box} from "@mui/material";
// import { AppBar, Toolbar, IconButton, Typography} from "@material-ui/core";
import { Link ,useNavigate} from "react-router-dom";

import "../Css/home.css";
import "../Css/table.css";
import "../Css/nav.css";
const Header = () =>{

    const navigate=useNavigate();

    return(
         <div className="header-nav">
          <ul>
            <li>
            <Link to="/dashboard" exact className='home-color linkk'>
                    Home
            </Link>
            </li>
            <li>
            <Link to="/subscribe" exact className='home-color linkk'>
                    Sub
            </Link>
            </li>
            <li>
            <Link to="/subscribers" exact className="home-color linkk">
                    All Sub
            </Link> 
            </li>
            <li>
            <Link to="/unsubscribers" exact className="home-color linkk">
                    All Unsub
            </Link> 
            </li>
            <li>
            <a className="home-color nav-item linkk" onClick={()=>{
                    // console.log("clicked");
                    sessionStorage.removeItem("username");
                    navigate('/');
                }}>
                    Logout
                </a>
            </li>
          </ul>  
        </div>
        // <Toolbar className="">
        //     <IconButton size="large" edge="start" color="inherit" aria-label="logo">
        //         {/* <img src={logo} alt="Logo" className="home-img" /> */}
        //     </IconButton>
        //     <Typography variant="h6" component="div" sx={{flexGrow:1}}>
        //         {/* @Econet */}
        //     </Typography>
        //     <Stack direction="row" spacing={2}>
                
        //         <Link to="/dashboard" exact className='home-color'>
        //             Home
        //         </Link>
        //         <Link to="/subscribe" exact className='home-color'>
        //             Sub
        //         </Link>
        //         {/* <Link to="/unsubscribe" exact className='home-color'> 
        //             Unsub
        //         </Link> */}
        //          <Link to="/subscribers" exact className="home-color">
        //             All Sub
        //         </Link> 
        //         <a className="home-color nav-item" onClick={()=>{
        //             // console.log("clicked");
        //             sessionStorage.removeItem("username");
        //             navigate('/');
        //         }}>
        //             Logout
        //         </a>
        //     </Stack>
        // </Toolbar>
    // </AppBar>
    );
}
export default Header;