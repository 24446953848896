import React, { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import Header from "../Components/Header";
import Login from "./Login";
import { allUnsubscribers, unsubFindByAni, unsubFindbyDate } from "../apis/api";

const AllUnsub = () => {
  let [data, addData] = useState([]);
  let [cPage, addCurrentPage] = useState();
  let [tPage, addTotalPage] = useState();

  useEffect(() => {
    document.title="Econet||All Unsubscribers";
    //Getting data
    axios.get(`${allUnsubscribers}`).then(
      (response) => {
        // console.log(response);
        let content = response.data.content;
        let totalPages = response.data.totalPages;
        let currentPage = response.data.currentPage;

        document.getElementById("spinnerDiv").style.display="none";

        // console.log("content is : ", content);
        // console.log("totalPages : ", totalPages);
        // console.log("currentPage : ", currentPage);

        addData(content);
        addCurrentPage(currentPage);
        addTotalPage(totalPages);

        document.getElementById("searchNav").style.display="none";
        document.getElementById("dateNav").style.display="none";
        document.getElementById("subNav").style.display="block";

        if(currentPage===0)
        {
          document.getElementById("subPrevious").style.display="none";
        }
        else
        {
          document.getElementById("subPrevious").style.display="block";
        }

        if(totalPages===currentPage)
        {
          document.getElementById("subNext").style.display="none";
        }
        else
        {
          document.getElementById("subNext").style.display="block";
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  //Normal Pagination
  const callPage = (reqPage) => {
    console.log("request page is ",reqPage);
    axios.get(`${allUnsubscribers}`+"?pageNumber="+reqPage)
    .then(
      (response)=>{
        // console.log(response);
        let content=response.data.content;
        let currentPage=response.data.currentPage;
        let totalPages=response.data.totalPages;

        // console.log("content is : ",content);
        // console.log("currentPage is : ",currentPage);
        // console.log("totalPages is : ",totalPages);

        addData(content);
        addCurrentPage(currentPage);
        addTotalPage(totalPages);

        //Hiding other Paginations & show its pagination
        document.getElementById("searchNav").style.display="none";
        document.getElementById("dateNav").style.display="none";
        document.getElementById("subNav").style.display="block";

        if(currentPage===0)
        {
          document.getElementById("subPrevious").style.display="none";
        }
        else
        {
          document.getElementById("subPrevious").style.display="block";
        }

        if(totalPages===currentPage)
        {
          document.getElementById("subNext").style.display="none";
        }
        else
        {
          document.getElementById("subNext").style.display="block";
        }
      },  
      (error)=>{
        console.log(error);
      }
    )
  };

  //Search by Ani
  const searchByAni = (reqPage) => {

    if(reqPage===undefined)
    {
      reqPage='0';
    }

    let ani=document.getElementById("search").value;

    if(ani==='')
    {
      callPage(0);
      document.getElementById("searchNav").style.display="none";
      document.getElementById("dateNav").style.display="none";
      document.getElementById("subNav").style.display="block";
    }
    else
    {
      console.log("Requested Page for searchByAni is ",reqPage);
      axios.get(`${unsubFindByAni}`+"?ani="+ani+"&pageNumber="+reqPage)
      .then(
        (response)=>{
          // console.log(response);
          let content=response.data.content;
          let totalPages=response.data.totalPages;
          let currentPage=response.data.currentPage;

          // console.log("content : ",content);
          // console.log("totalPages : ",totalPages);
          // console.log("currentPage : ",currentPage);

          addData(content);
          addTotalPage(totalPages);
          addCurrentPage(currentPage);

          //Hiding & Showing Paginations
          document.getElementById("subNav").style.display="none";
          document.getElementById("dateNav").style.display="none";
          document.getElementById("searchNav").style.display="block";

          if(currentPage===0)
          {
            document.getElementById("previous").style.display="none";
          }
          else
          {
            document.getElementById("previous").style.display="block";
          }

          if(totalPages===currentPage)
          {
            document.getElementById("next").style.display="none";
          }
          else
          {
            document.getElementById("next").style.display="block";
          }

        },
        (error)=>{
          console.log(error);
        }
      )  
    }
  };

  //Search By Date
  const searchByDate = (reqPage) => {
    if(reqPage===undefined)
    {
      reqPage=0;
    }

    console.log("Request Page for Search By Date is ",reqPage);
    let date=document.getElementById("searchDate").value;

    if(date==='')
    {
      callPage(0);
      document.getElementById("dateNav").style.display="none";
      document.getElementById("searchNav").style.display="none";
      document.getElementById("subNav").style.display="block";
    }
    else
    {
      axios.get(`${unsubFindbyDate}`+"?date="+date+"&pageNumber="+reqPage)
      .then(
        (response)=>{
          // console.log(response);
          let content=response.data.content;
          let currentPage=response.data.currentPage;
          let totalPages=response.data.totalPages;

          console.log("content : ",content);
          console.log("currentPage : ",currentPage);
          console.log("totalPages : ",totalPages);

          addData(content);
          addCurrentPage(currentPage);
          addTotalPage(totalPages);

          //Hiding & Showing Paginations:
          document.getElementById("subNav").style.display="none";
          document.getElementById("searchNav").style.display="none";
          document.getElementById("dateNav").style.display="block";

          if(currentPage===0)
          {
            document.getElementById("datePrevious").style.display="none";
          }
          else
          {
            document.getElementById("datePrevious").style.display="block";
          }

          if(totalPages===currentPage)
          {
            document.getElementById("dateNext").style.display="none";
          }
          else
          {
            document.getElementById("dateNext").style.display="block";
          }
        },
        (error)=>{
          console.log(error);
        }
      )
    }

  };

  let username = sessionStorage.getItem("username");
  if (username !== null) {
    return (
      <div>
        <Header />

        <div className="container mt-3 ">
          <div className="searchInput" style={{ marginLeft: "auto" }}>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Search by MSISDN"
              className="search"
              autoComplete="off"
              onChange={(e) => {
                setTimeout(function () {
                  // console.log("will run after 3 seconds")
                  searchByAni();
                }, 3000);
              }}
            />
          </div>

          <div className="searchDateInput">
            <input
              type="date"
              name="searchDate"
              id="searchDate"
              placeholder="Search by Date"
              className="searchh"
              autoComplete="off"
              onChange={(e) => {
                setTimeout(function () {
                  // console.log("will run after 2 seconds")
                  searchByDate();
                }, 2000);
              }}
            />
          </div>

          <div className="spinnerr" id="spinnerDiv">
            <TailSpin
              height="100"
              width="100"
              color="grey"
              ariaLabel="loading"
              className="spinnerrr"
            />
          </div>

          <table className="table table-dark table-bordered table-div">
            <thead>
              <tr>
                {/* <th>Sr. No</th> */}
                <th>MSISDN</th>
                <th>Pack</th>
                <th>Amount</th>
                <th>Unsub Date</th>
              </tr>
            </thead>
            {data.map((tbl, index) => {
              return (
                <tbody>
                  <tr>
                    {/* <td>{index+1}</td> */}
                    <td>{tbl.ani}</td>
                    <td>{tbl.pack}</td>
                    <td>{tbl.default_amount}</td>
                    <td>{tbl.sub_date_time}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>

          <nav aria-label="Page navigation example" id="subNav">
            <ul className="pagination pageDiv">
              <li className="page-item" id="subPrevious">
                <button
                  className="page-link"
                  onClick={() => callPage(cPage - 1)}
                >
                  Previous
                </button>
              </li>
              <li className="page-item">
                <button className="page-link" onClick={() => callPage(cPage)}>
                  {cPage + 1}
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => callPage(cPage + 1)}
                >
                  {cPage + 2}
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => callPage(cPage + 2)}
                >
                  {cPage + 3}
                </button>
              </li>
              <li className="page-item" id="subNext">
                <button
                  className="page-link"
                  onClick={() => callPage(cPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>

          <nav
            aria-label="Page navigation example"
            id="searchNav"
            style={{ display: "none" }}
          >
            <ul className="pagination pageDiv">
              <li className="page-item " id="previous">
                <button
                  className="page-link clr"
                  onClick={() => searchByAni(cPage - 1)}
                >
                  Previous
                </button>
              </li>
              <li className="page-item ">
                <button
                  className="page-link clr"
                  onClick={() => searchByAni(cPage)}
                >
                  {cPage + 1}
                </button>
              </li>
              <li className="page-item ">
                <button
                  className="page-link clr"
                  onClick={() => searchByAni(cPage + 1)}
                >
                  {cPage + 2}
                </button>
              </li>
              <li className="page-item ">
                <button
                  className="page-link clr"
                  onClick={() => searchByAni(cPage + 2)}
                >
                  {cPage + 3}
                </button>
              </li>
              <li className="page-item " id="next">
                <button
                  className="page-link clr"
                  onClick={() => searchByAni(cPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>

          <nav
            aria-label="Page navigation example"
            id="dateNav"
            style={{ display: "none" }}
          >
            <ul className="pagination pageDiv">
              <li className="page-item " id="datePrevious">
                <button
                  className="page-link clr2"
                  onClick={() => searchByDate(cPage - 1)}
                >
                  Previous
                </button>
              </li>
              <li className="page-item ">
                <button
                  className="page-link clr2"
                  onClick={() => searchByDate(cPage)}
                >
                  {cPage + 1}
                </button>
              </li>
              <li className="page-item ">
                <button
                  className="page-link clr2"
                  onClick={() => searchByDate(cPage + 1)}
                >
                  {cPage + 2}
                </button>
              </li>
              <li className="page-item ">
                <button
                  className="page-link clr2"
                  onClick={() => searchByDate(cPage + 2)}
                >
                  {cPage + 3}
                </button>
              </li>
              <li className="page-item " id="dateNext">
                <button
                  className="page-link clr2"
                  onClick={() => searchByDate(cPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Login />
      </div>
    );
  }
};
export default AllUnsub;
