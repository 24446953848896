import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import AllSubscribers from "../Pages/AllSubscribers";
import AllUnsub from "../Pages/AllUnsub";
import Dashboard from "../Pages/Dashboard";
import Login from "../Pages/Login";
import Logout from "../Pages/Logout";
import Subscribe from "../Pages/Subscribe";
import Unsubscribe from "../Pages/Unsubscribe";

const Menu=()=>{
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login/>} exact></Route>
                    <Route path="/dashboard" element={<Dashboard/>} exact></Route>
                    <Route path="/subscribe" element={<Subscribe/>} exact></Route>
                    {/* <Route path="/unsubscribe" element={<Unsubscribe/>} exact></Route> */}
                    <Route path="/subscribers" element={<AllSubscribers/>} exact></Route>
                    <Route path="/unsubscribers" element={<AllUnsub/>} exact></Route>
                    <Route path="/logout" element={<Logout/>} exact></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}
export default Menu;