import React,{useState, useEffect} from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {TailSpin} from  'react-loader-spinner'
import Login from "./Login";
import Header from "../Components/Header";
import { allSubscribers , find, searchByDateApi} from "../apis/api";
import "../Css/table.css";
// import { display } from "@mui/system";
import { unsubscribe, search } from "../apis/api";

const AllSubscribers=()=>{

    let username=sessionStorage.getItem("username");

    const[data, addData]=useState([]);

    const[cPage,setCurrentPage]=useState();
    const[tPage,setTotalPage]= useState();
    const[tData,setTableData]= useState([]);

    useEffect(()=>{
        document.title="Econet||All Subscribers";
        //to get all subscribers when first click on AllSub
        axios.get(`${allSubscribers}`)
        .then(
            (response)=>{
                // console.log(response.data);
                document.getElementById("spinnerDiv").style.display="none";
                let subs=response.data.content;
                let currentPage=response.data.currentPage;
                let totalPages=response.data.totalPages;

                // console.log("subs: ",subs);
                // console.log("currentPage: ",currentPage)
                // console.log("totalPages: ",totalPages)

                addData(subs);
                setTotalPage(totalPages);
                setCurrentPage(currentPage);

                document.getElementById("dateNav").style.display="none";
                document.getElementById("searchNav").style.display="none";
                document.getElementById("subNav").style.display="block";

                if(currentPage===0)
                {
                    document.getElementById("subPrevious").style.display="none";
                }
                else //if(currentPage!==0)
                {
                    document.getElementById("subPrevious").style.display="block";
                }

                if(currentPage===totalPages)
                {
                    document.getElementById("subNext").style.display="none";
                }
                else //if(currentPage!==totalPages)
                {
                    document.getElementById("subNext").style.display="block";
                }
            },
            (error)=>{
                console.log(error);
            }   
        );

    },[]);

    //to search by number
    const searchByAni=(reqPage)=>{
        let s=document.getElementById("search").value;
        // console.log("searched data is: ",s);
        
        if(reqPage==undefined)
        {
            reqPage='0';
        }

        if(s==='')
        {
            callPage(0);

            document.getElementById("searchNav").style.display="none";
            document.getElementById("dateNav").style.display="none";
            document.getElementById("subNav").style.display="block";
        }
        else
        {
            axios.get(`${find}`+'?ani='+s+'&pageNumber='+reqPage)
            .then(
                (response)=>{
                    //console.log(response);
                    let subs=response.data.content;
                    let currentPage=response.data.currentPage;
                    let totalPages=response.data.totalPages;
    
                    // console.log("subs: ",subs);
                    // console.log("currentPage: ",currentPage)
                    // console.log("totalPages: ",totalPages)
    
                    addData(subs);
                    setTotalPage(totalPages);
                    setCurrentPage(currentPage);
    
                    document.getElementById("subNav").style.display="none";
                    document.getElementById("dateNav").style.display="none";
                    document.getElementById("searchNav").style.display="block";
    
                    if(currentPage===0)
                    {
                        document.getElementById("previous").style.display="none";
                    }
                    else //if(currentPage!==0)
                    {
                        document.getElementById("previous").style.display="block";
                    }
    
                    if(currentPage===totalPages)
                    {
                        document.getElementById("next").style.display="none";
                    }
                    else //if(currentPage!==totalPages)
                    {
                        document.getElementById("next").style.display="block";
                    }
                },  
                (error)=>{
                    console.log(error);
                }
            )
        };    
        }

    //pagination for all subscribers only
    const callPage=(reqPage)=>{
        console.log("Request page for all subscribers is::::",reqPage);

        //Now Hit on api to get that page data
        axios.get(`${allSubscribers}`+'?pageNumber='+reqPage)
        .then(
            (response)=>{
               // console.log(response);
               let subs=response.data.content;
               let currentPage=response.data.currentPage;
               let totalPages=response.data.totalPages;

            //    console.log("subs: ",subs);
            //    console.log("currentPage: ",currentPage)
            //    console.log("totalPages: ",totalPages)

               addData(subs);
               setTotalPage(totalPages);
               setCurrentPage(currentPage);

               document.getElementById("dateNav").style.display="none";
               document.getElementById("searchNav").style.display="none";
               document.getElementById("subNav").style.display="block";

               if(currentPage===0)
               {
                   document.getElementById("subPrevious").style.display="none";
               }
               else
               {
                document.getElementById("subPrevious").style.display="block";
               }

               if(currentPage===totalPages)
               {
                   document.getElementById("subNext").style.display="none";
               }
               else
               {
                    document.getElementById("subNext").style.display="block";
               }
            },
            (error)=>{
                console.log(error);
            }
        )
    };

    //to unsub the user
    const unsub=(ani,pack)=>{
        // console.log("ani is ",ani);
        // console.log("pack is ",pack);

        let sendData=
        {
            "ani":ani.toString(),
            "pack":pack
        }

        axios.post(`${unsubscribe}`,sendData)
        .then(
            (response)=>{
                console.log(response);
                if(response.data.response==='deleted')
                {
                    toast.success("User Deleted Successfully");
                    searchByAni(0);
                }
                else
                {
                    toast.error("Something Went Wrong");
                }
            },
            (error)=>{
                console.log(error);
            }
        )
    };

    const searchByDate=(pageNumber)=>{
        //if admin want to search data by date
        let date=document.getElementById("searchDate").value;
        // console.log("searched date is ",date);

        if(pageNumber==undefined)
        {
            pageNumber=0;
        }

        if(date==='')
        {
            callPage(0);

            document.getElementById("searchNav").style.display="none";
            document.getElementById("dateNav").style.display="none";
            document.getElementById("subNav").style.display="block";
        }
        else
        {
            let api=`${searchByDateApi}`+"?date="+date+"&pageNumber="+pageNumber;
            console.log("api is ",api);
            axios.get(api)
            .then(
                (response)=>{
                    // console.log(response);

                    let subs=response.data.content;
                    let currentPage=response.data.currentPage;
                    let totalPages=response.data.totalPages;
     
                    // console.log("subs: ",subs);
                    // console.log("currentPage: ",currentPage)
                    // console.log("totalPages: ",totalPages)
     
                    addData(subs);
                    setTotalPage(totalPages);
                    setCurrentPage(currentPage);
     
                    document.getElementById("subNav").style.display="none";
                    document.getElementById("searchNav").style.display="none";
                    document.getElementById("dateNav").style.display="block";

                    if(currentPage===0)
                    {
                        document.getElementById("datePrevious").style.display="none";
                    }
                    else
                    {
                     document.getElementById("datePrevious").style.display="block";
                    }
     
                    if(currentPage===totalPages)
                    {
                        document.getElementById("dateNext").style.display="none";
                    }
                    else
                    {
                         document.getElementById("dateNext").style.display="block";
                    }

                },
                (error)=>{
                    console.log(error);
                }
            )
        }
    }

    if(username!=='')
    {
        return (
            <div>
            <ToastContainer/>
           
            <Header/>
            <div className="container mt-3 ">

            <div className="searchInput" style={{marginLeft:"auto"}}>
                <input type="text" name="search" id="search" 
                placeholder="Search by MSISDN" className="search"
                autoComplete="off" onChange={(e)=>{
                      setTimeout(function(){
                        // console.log("will run after 3 seconds")
                        searchByAni()
                      },3000)
                }}/>
            </div>

            <div className="searchDateInput">
                <input type="date" name="searchDate" id="searchDate" 
                placeholder="Search by Date" className="searchh"
                autoComplete="off" onChange={(e)=>{
                      setTimeout(function(){
                        // console.log("will run after 2 seconds")
                        searchByDate()
                      },2000)
                }}/>
            </div>

            <div className="spinnerr" id="spinnerDiv">
            <TailSpin
                height="100"
                width="100"
                color='grey'
                ariaLabel='loading'
                className="spinnerrr"
            />
            </div>

            <table className="table table-dark table-bordered table-div">
                <thead>
                    <tr>
                        {/* <th>Sr. No</th> */}
                        <th>MSISDN</th>
                        <th>Pack</th>
                        <th>Amount</th>
                        <th>SubDate</th>
                        <th>Action</th> 
                    </tr>
                </thead>
                     {data.map((tbl,index)=>{
                    return (
                        <tbody>
                        <tr>
                            {/* <td>{index+1}</td> */}
                            <td>{tbl.ani}</td>
                            <td>{tbl.pack}</td>
                            <td>{tbl.default_amount}</td>
                            <td>{tbl.sub_date_time}</td>
                            <td><button className="btn btn-primary"
                                  onClick={()=>{
                                    unsub(tbl.ani, tbl.pack)
                                  }}
                                >Unsub</button></td>
                        </tr>
                    </tbody>
                     );
                 })}

        
            </table>

    <nav aria-label="Page navigation example" id="subNav">
        <ul className="pagination pageDiv">
            <li className="page-item" id="subPrevious"><button className="page-link" onClick={()=>callPage(cPage-1)}>Previous</button></li>
            <li className="page-item"><button className="page-link" onClick={()=>callPage(cPage)}>{cPage+1}</button></li>
            <li className="page-item"><button className="page-link" onClick={()=>callPage(cPage+1)}>{cPage+2}</button></li>
            <li className="page-item"><button className="page-link" onClick={()=>callPage(cPage+2)}>{cPage+3}</button></li>
            <li className="page-item" id="subNext"><button className="page-link" onClick={()=>callPage(cPage+1)}>Next</button></li>
        </ul>
    </nav>

    <nav aria-label="Page navigation example" id="searchNav" style={{display:"none"}}>
        <ul className="pagination pageDiv">
            <li className="page-item " id="previous"><button className="page-link clr" onClick={()=>searchByAni(cPage-1)}>Previous</button></li>
            <li className="page-item "><button className="page-link clr" onClick={()=>searchByAni(cPage)}>{cPage+1}</button></li>
            <li className="page-item "><button className="page-link clr" onClick={()=>searchByAni(cPage+1)}>{cPage+2}</button></li>
            <li className="page-item "><button className="page-link clr" onClick={()=>searchByAni(cPage+2)}>{cPage+3}</button></li>
            <li className="page-item " id="next"><button className="page-link clr" onClick={()=>searchByAni(cPage+1)}>Next</button></li>
        </ul>
    </nav>

    <nav aria-label="Page navigation example" id="dateNav" style={{display:"none"}}>
        <ul className="pagination pageDiv">
            <li className="page-item " id="datePrevious"><button className="page-link clr2" onClick={()=>searchByDate(cPage-1)}>Previous</button></li>
            <li className="page-item "><button className="page-link clr2" onClick={()=>searchByDate(cPage)}>{cPage+1}</button></li>
            <li className="page-item "><button className="page-link clr2" onClick={()=>searchByDate(cPage+1)}>{cPage+2}</button></li>
            <li className="page-item "><button className="page-link clr2" onClick={()=>searchByDate(cPage+2)}>{cPage+3}</button></li>
            <li className="page-item " id="dateNext"><button className="page-link clr2" onClick={()=>searchByDate(cPage+1)}>Next</button></li>
        </ul>
    </nav>

        </div>
            </div>
        );    
    }
    else
    {
        return (
            <div>
                <Login/>
            </div>
        );
    }
}
export default AllSubscribers;