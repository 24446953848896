import React from "react";
import { useNavigate } from 'react-router-dom';

const Logout=()=>{

    // let username=sessionStorage.getItem("username");
    sessionStorage.removeItem("username");
    const navigate=useNavigate();
    navigate("/");

    // return(
    //     <div></div>
    // );
}

export default Logout;