// const pre="http://91.205.172.123:7474/EconetCCPortalBackend/";
//  const pre="http://localhost:3438/";
// const pre="https://www.reactjs.games4ng.com/"; this is recently used domain for cc portal econet
const pre= "https://backendcci.econetbigcash.com/";

// const allSubscribers="http://localhost:3438/allSubscribers/";
const login=pre+"login";
const todaySub=pre+"todaySub";

const subscribe=pre+"subscribeUser";
const search=pre+"searchUser";

const unsubscribe=pre+"unsubscribeUser";
const allSubscribers=pre+"pagination";
const find=pre+"searchAni";
const searchByDateApi=pre+"searchByDate";

const allUnsubscribers=pre+"getAllUnsub";
const unsubFindByAni=pre+"searchByAniUnsub";
const unsubFindbyDate=pre+"searchByDateUnsub";

export {login};
export {todaySub};
export {subscribe};
export {search};
export {unsubscribe};
export {allSubscribers};
export {find};
export {searchByDateApi};
export {allUnsubscribers, unsubFindByAni, unsubFindbyDate};
