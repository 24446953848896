import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import {ToastContainer, toast } from 'react-toastify';
import "../Css/logincss.css";
import {subscribe} from "../apis/api";
import Login from "./Login";

const Subscribe = () =>{

    useEffect(()=>{
        document.title="Econet||Subscribe";
    },[]);

    const[data,addData]=useState({});

    const username=sessionStorage.getItem("username");

    if(username!==null)
    {
        const onSubmit=(e)=>{
            console.log(data);
            hitOnApi(data);
            e.preventDefault();
        };

        const hitOnApi=(data)=>{
            axios.post(`${subscribe}`,data)
            .then(
                (response)=>{
                    // console.log(response);
                    if(response.data.response==='already')
                    {
                        //user already exist
                        toast.success("User Already Exist");
                    }
                    else if(response.data.response==='done')
                    {
                        //user Added successfully
                        toast.success("User Added Successfully");
                        addData({});
                        document.getElementById("ani").value="";
                        document.getElementById("pack").value="Choose";
                    }
                    else
                    {
                        //something went wrong
                        toast.success("Something Went Wrong");
                    }
                },
                (error)=>{
                    console.log(error);
                    toast.error("Something Went Wrong");
                }
            )
        };
        
        return(
            <div>
                <ToastContainer/>
                <Header/>
                <div className="main">
                <div className="sub-main-subscribe">
                    <div>
                        <div>
                            <h2 className="btn btn-outline-secondary font">Subscribe User Here</h2>
                            <form onSubmit={onSubmit}>
                                <div>
                                    {/* <img src={email} alt="Email" className="email"/> */}
                                    <input type="text" name="ani" id="ani"
                                    placeholder="MSISDN" autoComplete="off" required
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani:e.target.value})
                                    }}/>
                                </div>
                                <div className="second-div">
                                    {/* <img src={password} alt="Password" className="email"/> */}
                                    <select id="pack" name="pack" required className="name"
                                    onChange={(e)=>{
                                        addData({...data,pack:e.target.value})
                                    }}>
                                        <option value="LGAMING_D">Choose</option>
                                        <option value="LGAMING_D">Daily</option>
                                        <option value="LGAMING_W">Weekly</option>
                                        <option value="LGAMING_M">Monthly</option>
                                    </select>
                                </div>
                                <div className="bttn-div">
                                    <button className="bttn">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>                
        );    
    }
    else
    {
        return(
            <div>
                <Login/>
            </div>
        );
    }

}
export default Subscribe;