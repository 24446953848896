import React from "react";
import Body from "../Components/Body";
import Header from "../Components/Header";
import Login from "./Login";

const Dashboard = () =>{

    const username=sessionStorage.getItem("username");

    if(username!==null)
    {
        return (
            <div>
                <Header/>
                <Body/>
            </div>
        );    
    }
    else
    {
        return (
            <div>
                <Login/>
            </div>
        );
    }
}
export default Dashboard;