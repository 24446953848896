import './App.css';
import Menu from './Components/Menu';

function App() {
  return (
    <div>
      <Menu/>
    </div>
  );
}

export default App;
